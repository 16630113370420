import React from 'react';

import classes from './ButtonDefault.module.css';

const ButtonDefault = (props) => {
    return (
        <button className={classes.button}>{props.cta}</button>
    )
};

export default ButtonDefault;