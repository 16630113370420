import React from 'react';

import AboutContent from './AboutContent/AboutContent';

import classes from './About.module.css';
import sectionStyle from '../Section.module.css';

function About() {
    return (
        <React.Fragment>
            <section className={`${sectionStyle.section} ${sectionStyle.sectionSM} ${classes.spacer} ${classes.container}`} id='about'>
                <div className={`${classes.about}`} >
                    <h2>About Yong</h2>
                    <div>
                        <AboutContent />
                    </div>
                </div>
                <div className={sectionStyle.halfSection}>
                            <div className={classes.imgContainer}>
                                <img src={require('../../assets/img/fam.jpg')} alt="" className={classes.family} />
                            </div>
                        </div>
            </section>
        </React.Fragment>
    )
}

export default About;