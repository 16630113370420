import React, { useState } from 'react';

import Card from '../Card/Card';

import Calc from '../../../assets/img/projects/crypto-calc.png'
import Ska from '../../../assets/img/projects/ska-or-nah.png'

import classes from './Carousel.module.css';

const Carousel = props => {
    const [index, setIndex] = useState(0);
    const length = 2;

    const prevHandler = () => {
        const newIndex = index - 1;
        setIndex(newIndex < 0 ? length - 1 : newIndex);
    };

    const nextHandler = () => {
        const newIndex = index + 1;
        setIndex(newIndex >= length ? 0 : newIndex);
    };

    return (
        <div className={classes.carouselContainer}>
            <div
                className={classes.carousel}
                style={{ transform: `translateX(-${index * 100}%)` }}>
                <Card
                    title='Crypto Calculator'
                    body='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pulvinar, est ut iaculis pretium, nulla est iaculis eros, a eleifend sapien est rutrum risus. Nulla facilisis diam nunc, iaculis consectetur dolor elementum et.'
                    html='active'
                    css='active'
                    javascript='active'
                    react=''
                    nodeJS=''
                    mongoDB=''
                    img={Calc}
                    alt='Crypto Currency'
                    demo='https://crypto-calc.onrender.com/'
                    github='https://github.com/yxxxng/cyrpto-calc'
                />
                <Card
                    title='Ska or Nah'
                    body='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pulvinar, est ut iaculis pretium, nulla est iaculis eros, a eleifend sapien est rutrum risus. Nulla facilisis diam nunc, iaculis consectetur dolor elementum et.'
                    html='active'
                    css='active'
                    javascript='active'
                    react=''
                    nodeJS='active'
                    mongoDB='active'
                    img={Ska}
                    alt='Ska Or Nah'
                    demo='https://ska-or-nah.onrender.com'
                    github='https://github.com/yxxxng/ska-or-nah'
                />
                {/* <Card
                    title='Portfolio'
                    body='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pulvinar, est ut iaculis pretium, nulla est iaculis eros, a eleifend sapien est rutrum risus. Nulla facilisis diam nunc, iaculis consectetur dolor elementum et.'
                    html='active'
                    css='active'
                    javascript='active'
                    react='active'
                    nodeJS=''
                    mongoDB=''
                    alt='Portfolio'
                    demo='https://crypto-calc.onrender.com/'
                    github=''
                /> */}
            </div>
            <div className={classes.carouselControls}>
                <button className={classes.button} onClick={prevHandler}>Previous</button>
                <span className={classes.carouselPage}>{index + 1}</span>
                <button className={classes.button} onClick={nextHandler}>Next</button>
            </div>
        </div>
    )
}

export default Carousel;