import React from 'react';

import classes from './HeroImg.module.css';

function HeroImg() {
    return (
        <div className={classes.imgContainer}>
            <div className={`${classes.HeroImg}`}>
                <img src={ require('../../../assets/img/yong.JPG')} alt="" className={classes.yong} />
            </div>
        </div>
    )
}

export default HeroImg;