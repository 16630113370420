import React from 'react';

import classes from './AboutContent.module.css';
import sectionStyle from '../../Section.module.css';

function AboutContent() {
    return (
        <section className={classes.aboutContentContainer}>
            <div className={sectionStyle.halfSection}>
                <p>My professional career has been focused in digital marketing. At my core, I'm a creative problem solver that wants to help improve the efficiency and functionality of the projects I'm working on.</p>

                <p>I began coding out of my life-long interest in technology and my natural curiosity for tinkering. I belive that my natural problem solving and creativity are better served in the programming world where all problems have a solution and thinking big leads to learning more about the technology.</p>

                <p>Outside of work, I spend a lot of time playing music, going to concerts, collecting sneakers, and thrifting. I've also been  getting into hiking with my fiancée and our dog since we've moved to West Virigina.</p>
            </div> 
        </section>
    )
}

export default AboutContent;