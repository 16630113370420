import React from 'react';

import classes from './CardIcons.module.css';
import html from '../../../assets/img/skills/html.png';
import css from '../../../assets/img/skills/css.png';
import javascript from '../../../assets/img/skills/javascript.png';
import nodeJS from '../../../assets/img/skills/nodejs.png';
import reactJS from '../../../assets/img/skills/react.png';
import mongoDB from '../../../assets/img/skills/mongodb.png';


const CardIcons = props => {
    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={`
                ${props.skills.html === 'active' ? `${classes.active}` : `${classes.default}`} } 
                `}>
                    <img src={html} alt="html" className={classes.skill} />
                </div>
                <div className={`
                ${props.skills.css === 'active' ? `${classes.active}` : `${classes.default}`} } 
                `}>
                    <img src={css} alt="css" className={classes.skill} />
                </div>
                <div className={`
                ${props.skills.javascript === 'active' ? `${classes.active}` : `${classes.default}`} } 
                `}>
                    <img src={javascript} alt="javascript" className={classes.skill} />
                </div>
                
            </div>
            <div className={classes.container}>
                <div className={`
                ${props.skills.react === 'active' ? `${classes.active}` : `${classes.default}`} } 
                `}>
                    <img src={reactJS} alt="react" className={classes.skill} />
                </div>
                <div className={`
                ${props.skills.nodeJS === 'active' ? `${classes.active}` : `${classes.default}`} } 
                `}>
                    <img src={nodeJS} alt="nodeJS" className={classes.skill} />
                </div>
                <div className={`
                ${props.skills.mongoDB === 'active' ? `${classes.active}` : `${classes.default}`} } 
                `}>
                    <img src={mongoDB} alt="mongoDB" className={classes.skill} />
                </div>
                
            </div>
        </React.Fragment>
    )
};

export default CardIcons