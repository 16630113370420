import './App.css';

import Nav from './Components/Nav';
import Header from './Components/Header/Header';
import Projects from './Components/Projects/Projects';
import Skills from './Components/Skills/Skills';
import About from './Components/About/About';

function App() {
  return (
    <div className='main'>
      <Nav />
      <Header />
      <Projects />
      <Skills />
      <About />
    </div>
  )
}

export default App;
