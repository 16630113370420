import React from 'react';

import SkillIcons from './SkillIcons/SkillIcons'

import classes from './Skills.module.css';
import sectionStyle from '../Section.module.css';

function Skills() {
    return (
        <React.Fragment>
            <section id='skills' className={`${sectionStyle.section} ${sectionStyle.sectionMD} ${classes.spacer}`}>
                <div className={classes.skills}>
                    <h2>Skills</h2>
                    <SkillIcons />
                </div>
            </section>
        </React.Fragment>
    )
}

export default Skills;