import React from 'react';

import CardIcons from './CardIcons';
import CardButtons from './CardButtons';

import classes from './Card.module.css';

const Card = props => {
    return (
        <div className={classes.cardContainer}>
            <div className={classes.cardOuter}>
                <div>
                    <img className={classes.img} src={props.img} alt={props.alt} />
                </div>
                <div className={classes.cardDetails}>
                    <h3>{props.title}</h3>
                    <hr />
                    <CardIcons skills={props} />
                    <p className={classes.cardBody}>{props.body}</p>
                    <CardButtons demo={props.demo} github={props.github} />
                </div>
            </div>
        </div>
    )
};


export default Card;