import React from 'react';

import GreetingText from './HeaderContent/GreetingText';
import HeroImg from './HeaderContent/HeroImg';

import classes from './Header.module.css';
import sectionStyle from '../Section.module.css';

const Header = props => {
    return (
        <React.Fragment>
            <section id='greeting' className={`${sectionStyle.section} ${classes.greeting}`}>
                <GreetingText />
                <HeroImg />
            </section>
        </React.Fragment>
    )
}

export default Header