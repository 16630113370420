import React from 'react';

import sectionStyles from '../../Section.module.css'
import classes from './SkillIcons.module.css'

function SkillIcons() {
    return (
        <section>
            <div className={classes.iconContainer}>
                <div>
                    <img src={require('../../../assets/img/skills/javascript.png')} alt="" className={classes.icon} />
                    <p>Javascript</p>
                </div>
                <div>
                    <img src={require('../../../assets/img/skills/react.png')} alt="" className={classes.icon} />
                    <p>React</p>
                </div>
                <div>
                    <img src={require('../../../assets/img/skills/html.png')} alt="" className={classes.icon} />
                    <p>HTML</p>

                </div>
                <div>
                    <img src={require('../../../assets/img/skills/css.png')} alt="" className={classes.icon} />
                    <p>CSS</p>
                </div>
            </div>
            <div className={classes.iconContainer}>
                <div>
                    <img src={require('../../../assets/img/skills/nodejs.png')} alt="" className={classes.icon} />
                    <p>Node</p>
                </div>
                <div>
                    <img src={require('../../../assets/img/skills/mongodb.png')} alt="" className={classes.icon} />
                    <p>MongoDB</p>
                </div>
                <div>
                    <img src={require('../../../assets/img/skills/python.png')} alt="" className={classes.icon} />
                    <p>Python</p>
                </div>
                <div>
                    <img src={require('../../../assets/img/skills/sql.png')} alt="" className={classes.icon} />
                    <p>mySQL</p >
                </div >
            </div>
        </section>
    )
}

export default SkillIcons;