import React from 'react';
import classes from './GreetingText.module.css';

function GreetingText() {
  return (
    <div className={classes.greetingContent}>
      <div className={classes.greetingText}>
        <h1>Hi, I'm Yong</h1>
        <h4>Front End Developer, Data Analyst, Creative Strategist</h4>
        <p>
          I am a self-taught developer looking to utilize my creativity, eye for
          detail, & drive to learn to solve problems and help improve and
          develop digital products, platforms, and tools.
        </p>
        <p>
          I have primarily focused on building with frontend technologies like{' '}
          <strong>Javascript & ReactJS</strong> but have also worked with{' '}
          <strong>Python, NodeJS, MongoDB, & MySQL.</strong>
        </p>
        <div className={classes.btnContainer}>
          <a className={`${classes.btn} ${classes.btnAbout}`} href="#projects">
            Explore Projects
          </a>
          <a
            className={`${classes.btn} ${classes.btnAbout}`}
            href={require('../../../assets/Yong Los - Resume 2022.pdf')}
            target="_blank"
            rel="noreferrer"
          >
            View Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default GreetingText;
