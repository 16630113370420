import React from 'react';

// import ProjectGrid from './ProjectGrid/ProjectGrid';
// import Card from '../UI/Card/Card';
import Carousel from '../UI/Carousel/Carousel';

import classes from './Projects.module.css';
import sectionStyle from '../Section.module.css';

function Projects() {
    return (
        <React.Fragment>
            <section id='projects' className={`${sectionStyle.section} ${classes.spacer}`}>
                <div className={classes.projects}>
                    <h2>Projects</h2>
                    <div className={classes.projectContainer}>
                        <Carousel />
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default Projects;
