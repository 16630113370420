import React from 'react';

import ButtonDefault from '../Buttons/ButtonDefault';

import classes from './CardButtons.module.css';

const CardButtons = (props) => {
    return (
        <React.Fragment>
            <div className={classes.container}>
                <a href={props.demo} target='_blank' rel='noreferrer'><ButtonDefault cta='Live Demo' /></a>
                <a href={props.github} target='_blank' rel='noreferrer'><ButtonDefault cta='Learn More' /></a>
            </div>
        </React.Fragment>
    )
}

export default CardButtons;