import React from 'react';
// import { Link } from 'react-router-dom';
import classes from './Nav.module.css';

function Nav() {
    return (
        <div className={classes.navContainer}>
            <nav className={classes.nav}>
                <li className={classes.menuItem}><a className={classes.link} href="/#">Home</a></li>
                <li className={classes.menuItem}><a className={classes.link} href="#projects">Projects</a></li>
                <li className={classes.menuItem}><a className={classes.link} href="#skills">Skills</a></li>
                <li className={classes.menuItem}><a className={classes.link} href="#about">About</a></li>
            </nav>
        </div>
    )
}

export default Nav;